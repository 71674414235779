import { Chip, Divider, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'

export const ProjectForCustomerItem = ({itemData}) => {

  const {id} = itemData;

  const fnClickItem = ()=>{
    
  }

  return (
    <>
    <ListItem>
      <ListItemText>
        <Typography variant='h5' >{itemData.name}</Typography>
      </ListItemText>
      <Chip label={itemData.value} onClick={fnClickItem} color={"secondary"} />
    </ListItem>
    <Divider />
    </>
  )
}
