import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { onTitleEdit, onBreadcrumbEdit } from 'app/redux/actions/generalData';
import { getPrivilegeData } from 'app/utils/helpers';
import SpeedDialButton from 'app/components/SpeedDialButton';
import ToastAlert from 'app/components/ToastAlert';
import Loading from "app/components/loading";
import Content from './Content';

const Users = (props) => {
  document.title = "Techmasys - Users"
  const dispatch = useDispatch();
  const {activeLayout, setActiveLayout } = useJumboApp();
  const [isLoading, setLoading] = useState(false);
  const [actions, setActions] = useState([]);
  const {sweetAlerts} = ToastAlert();

  const PRIVILEGE_ID2 = 25;
  const controlAdmin = getPrivilegeData(PRIVILEGE_ID2);

  if(activeLayout !== LAYOUT_NAMES.VERTICAL_DEFAULT){
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }

  useEffect(()=>{
    dispatch(onTitleEdit('users'));
    dispatch(onBreadcrumbEdit(['settings', 'users']));
  },[]);

  return (
    <>
      <Loading show={isLoading} />
      <Content controlAdmin={controlAdmin} setLoading={setLoading} sweetAlerts={sweetAlerts} setActions={setActions} {...props} />
      <SpeedDialButton actions={actions}/>
    </>
  )
}

export default Users;