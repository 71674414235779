import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {useTranslation} from "react-i18next";
import { useJumboApp } from "@jumbo/hooks";
import ToastAlert from 'app/components/ToastAlert';
import Loading from "app/components/loading";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { getPrivilegeData } from 'app/utils/helpers';
import { onTitleEdit, onBreadcrumbEdit } from 'app/redux/actions/generalData';
import Content from './Content';

const BanksPage = (props) => {
  const {t} = useTranslation();
  document.title = "Techmasys - Banks"
  const dispatch = useDispatch();
  const { activeLayout, setActiveLayout } = useJumboApp();
  const [isLoading, setLoading] = useState(false);
  const {sweetAlerts} = ToastAlert();

  const PRIVILEGE_ID2 = 24;
  const controlAdmin = getPrivilegeData(PRIVILEGE_ID2);

  if(activeLayout !== LAYOUT_NAMES.VERTICAL_DEFAULT){
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }

  useEffect(()=>{
    dispatch(onTitleEdit('settings.banks'));
    dispatch(onBreadcrumbEdit(['settings', 'banks']));
  },[]);

  return(
    <>
      <Loading show={isLoading} />
      <Content controlAdmin={controlAdmin} setLoading={setLoading} sweetAlerts={sweetAlerts} t={t} {...props} />
    </>
  )
}

export default BanksPage;