const ListUrlPublics = [
  '/login',
  '/getCompanyList',
  '/getModuleList',
  '/validCashier',
  '/employeeForLogin',
  '/sendEmailEmployee',
  '/loginEmployee',
  '/employeeProfileToEdit'
];

export default ListUrlPublics