import { TextField } from '@mui/material'
import React from 'react'

export const InputBox = ({label, name, value, onChange, ...rest}) => {
  return (
  <TextField
    id={name}
    label={label}
    variant="standard"
    name={name}
    value={value}
    onChange={onChange}
    fullWidth
    {...rest}
  />
  )
}
